<template>
  <div class="customerService">
    <div class="header">
      <span>工作时间</span>
      <span>09:00 ~ 18:00</span>
    </div>
    <!-- 常见的问题 -->
    <div class="commonProblem">
      <p @click="commonProblem">常见的问题</p>
    </div>
    <!-- 客服二维码 -->
    <div class="qrcode" v-if="cid != 928">
      <img :src="url" alt="" />
    </div>

    <div class="btn">
      <a href="tel:027-88386663" style="color: #fff"
        >点击拨打客服电话：027-88386663</a
      >
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { server } from '../api/getUserInfo';
export default {
  name: 'customerService',
  data() {
    return {
      url: '',
      cid: this.$route.query.cid ? this.$route.query.cid : '', //增加cid为928的判断(百度审核)
    };
  },
  mounted() {
    server({ type: 1 })
      .then((res) => {
        if (res.data.code == 200) {
          this.url = res.data.data;
        } else {
          Toast(res.data.msg);
        }
      })
      .catch((err) => {
        Toast(err);
      });
  },
  methods: {
    commonProblem() {
      this.$router.push({
        path: '/commonProblem',
      });
    },
  },
};
</script>

<style lang="less" scoped>
.customerService {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.header {
  width: 100vw;
  height: 100px;
  // background: red;
  line-height: 100px;
  font-size: 32px;
  display: flex;
  text-align: left;
  span:nth-child(1) {
    flex: 1;
    margin-left: 36px;
  }
  span:nth-child(2) {
    flex: 1;
  }
}
.commonProblem {
  width: 96vw;
  height: 100px;
  background: #f6f6f6;
  margin: 0 auto;
  p {
    text-align: left;
    line-height: 100px;
    font-size: 30px;
    padding-left: 26px;
    color: #551a8b;
    text-decoration: underline;
  }
}
.qrcode {
  width: 100%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 150px;
  text-align: center;
  img {
    width: 700px;
    height: 700px;
    margin: 0 auto;
  }
}
.btn {
  width: 700px;
  height: 90px;
  font-size: 30px;
  color: white;
  text-align: center;
  background-color: #ff7f2e;
  border-radius: 10px;
  line-height: 90px;
  margin: 0 auto;
}
</style>
